import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Actions from 'src/state/root-actions';

const connector = connect(undefined, {
    logout: () => Actions.auth.logout.trigger(),
});

const Logout = ({ logout }: ConnectedProps<typeof connector>) => {
    useEffect(() => {
        logout();
    }, [logout]);
    return null;
};

export default connector(Logout);
